import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthRoutingPaths } from '../../app-routing.module';


@Component({
	selector: 'tt-start',
	templateUrl: 'start.component.html',
	styleUrls: ['start.component.scss'],
	standalone: false,
})
export class StartComponent implements OnInit {
	public activeRouteSegment: string;
	public startRouteSegmentList = AuthRoutingPaths;

	public constructor(
		private viewRouter: Router,
		private activatedRoute: ActivatedRoute,
	) {
	}

	public ngOnInit(): void {
		this.activeRouteSegment = this.viewRouter.url.split('/').pop().split('?').shift();
		this.viewRouter.events.pipe(
			filter((routerEvent: any) => {
				return routerEvent instanceof NavigationEnd;
			}))
			.subscribe(() => {
				this.activeRouteSegment = this.viewRouter.url.split('/').pop().split('?').shift();
			});
	}

	public switchView(): void {
		this.viewRouter.navigate([(this.activeRouteSegment === AuthRoutingPaths.SignIn) ? AuthRoutingPaths.Register : AuthRoutingPaths.SignIn], { relativeTo: this.activatedRoute });
	}
}
