import { ComponentRef, Inject, Injectable } from '@angular/core';
import {
	GaActionEnum,
	GoogleAnalyticsInitializer,
	GoogleAnalyticsRouterInitializer,
	GoogleAnalyticsService,
	GtagFn,
	IGoogleAnalyticsSettings,
	NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
	NGX_GTAG_FN,
} from 'ngx-google-analytics';

import { DomainEntityTypeName, GQLMutationName, RunConfiguration } from '@tasktrain/shared';
import { environment } from '@environment/browser';


@Injectable({ providedIn: 'root' })
export class AnalyticsService {
	private isInitialized: boolean;

	public constructor(
		@Inject(NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN) private googleAnalyticsSettingsToken: IGoogleAnalyticsSettings,
		@Inject(NGX_GTAG_FN) private googleTagFunction: GtagFn,
		private googleAnalyticsService: GoogleAnalyticsService,
	) {
	}

	public initializeGoogleAnalytics(bootstrapComponent: ComponentRef<unknown>): void {
		if (environment.NAME === RunConfiguration.Production && !this.isInitialized) {
			Promise.all([
				GoogleAnalyticsInitializer(this.googleAnalyticsSettingsToken, this.googleTagFunction, document)(),
				GoogleAnalyticsRouterInitializer({}, this.googleAnalyticsService)(bootstrapComponent), // @ToDo: Consider restricting to user registration only: `{include: [AuthRoutingPaths.Register]}`
			]).then(() => {
				this.isInitialized = true;
			}).catch((error: unknown) => {
				this.isInitialized = false;
			});
		}
	}

	/** Log Google Analytics Event https://support.google.com/analytics/answer/1033068?hl=en#
	 *
	 * @param action - identifier name for specific user action: https://support.google.com/analytics/answer/9267735?hl=en&ref_topic=9756175
	 * @param category - generic group for related actions
	 * @param label - subject or predicate of action
	 * @param value - numeric value associated with action
	 * @param interaction - whether to include in bounce rate calculations
	 */
	public logAction(
		action: GaActionEnum | GQLMutationName | string,
		category?: DomainEntityTypeName | string,
		label?: string,
		value?: number,
		interaction?: boolean,
	): void {
		if (this.isInitialized) {
			this.googleAnalyticsService.event(action, category, label, value, interaction);
		}
	}

	/** Log application error as Google Analytics exception */
	public logError(description?: string, fatal?: boolean): void {
		if (this.isInitialized) {
			this.googleAnalyticsService.exception(description, fatal);
		}
	}
}
