import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { environment } from '@environment/browser';
import { RunConfiguration } from '@tasktrain/shared';
import { AppModule } from './app/app.module';

if (environment.NAME === RunConfiguration.Production) {
	enableProdMode();
}
void platformBrowserDynamic().bootstrapModule(AppModule);
