/** encapsulation class for action */
export class ContentAttachedCreateMessage {
	public constructor(
		public accountId: string,
		public contentIndex: number,
		public procedureId?: string,
		public procedureStepId?: string,
		public procedureStepIndexPath?: number[],
		public manualId?: string,
	) {
	}
}
