/** encapsulation class for action */
export class ContentLinkedCreateMessage {
	public constructor(
		public accountId: string,
		public contentIndex: number,
		public manualId: string,
		public procedureId?: string,
		public procedureStepId?: string,
		public procedureStepIndexPath?: number[],
	) {
	}
}

