import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { WA_LOCATION } from '@ng-web-apis/common';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthenticationService } from './authentication.service';


@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export class ClientUpdateService {
	public constructor(
		@Inject(WA_LOCATION) private readonly location: Location,
		private serviceWorkerUpdateService: SwUpdate,
		private applicationService: ApplicationRef,
		private confirmationService: ConfirmationService,
		private authenticationService: AuthenticationService,
	) {
		if (serviceWorkerUpdateService.isEnabled) {
			this.pollForVersionUpdate();
			this.promptForRefreshOnVersionUpdate();
		}
	}

	/** Poll for application updates after application stabilizes in addition to default initial checks
	 *  https://angular.io/guide/service-worker-communications#checking-for-updates
	 */
	private pollForVersionUpdate(): void {
		const applicationIsStable = this.applicationService.isStable.pipe(
			first((isStable: boolean) => {
				return isStable === true;
			}),
		);
		const everyFifteenMinutes = interval(15 * 60 * 1000);
		const everyFifteenMinutesOnceAppIsStable = concat(applicationIsStable, everyFifteenMinutes);
		everyFifteenMinutesOnceAppIsStable
			.pipe(untilDestroyed(this))
			.subscribe(() => {
				void this.serviceWorkerUpdateService.checkForUpdate();
			});
	}

	private promptForRefreshOnVersionUpdate(): void {
		this.serviceWorkerUpdateService.versionUpdates
			.pipe(untilDestroyed(this))
			.subscribe((versionUpdateEvent) => {
				if (versionUpdateEvent.type === 'VERSION_DETECTED') {
					void this.authenticationService.isSignedIn().then((isSignedIn) => {
						if (isSignedIn) {
							this.confirmationService.confirm(
								{
									header: 'Reload Required',
									message: '<p>A new release of TaskTrain is available!</p>Press <strong>Update</strong> to load the new version.</br>Press <strong>Cancel</strong> if you have unsaved changes to copy.',
									acceptLabel: 'Update',
									acceptIcon: 'tt-icon-refresh',
									acceptButtonStyleClass: 'tt-btn-update',
									rejectLabel: 'Cancel',
									rejectIcon: 'tt-icon-cancel',
									rejectButtonStyleClass: 'tt-btn-cancel',
									accept: (): void => {
										void this.serviceWorkerUpdateService.activateUpdate().then(() => {
											this.location.reload();
										});
									},
								},
							);
						} else {
							void this.serviceWorkerUpdateService.activateUpdate().then(() => {
								this.location.reload();
							});
						}
					});
				}
			});
	}
}
