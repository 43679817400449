/**
 * Truncate a string to a specified length, appending an ellipsis if truncated.
 * @param input - The string to truncate.
 * @param maxLength - The maximum allowed length of the string, including the ellipsis if added.
 * @returns The truncated string with ellipsis if necessary.
 */
export const truncateWithEllipsis = (input: string, maxLength: number = 111): string => {
	if (maxLength <= 0) {
		throw new Error('maxLength must be greater than 0.');
	}
	const trimmedInput = input.trim();
	if (trimmedInput.length <= maxLength) {
		return trimmedInput;
	} else {
		return `${trimmedInput.slice(0, maxLength - 1)}…`;
	}
};
