/* eslint-disable @stylistic/quote-props,@stylistic/quotes,@stylistic/indent */
export const environment = {
  "AWS_BEDROCK_REGION": "us-east-2",
  "AWS_CONTENT_BUCKET_NAME": "tasktrain-content-staging",
  "AWS_CONTENT_BUCKET_REGION": "us-east-2",
  "CLIENT_URL": "https://staging.tasktrain.app",
  "GOOGLE_ANALYTICS_CODE": "G-CEQ1C9DJEN",
  "GOOGLE_CLIENT_ID": "900850068450-3kms08gsspr7a2ceagd00pep9vbn2tpb.apps.googleusercontent.com",
  "GOOGLE_REDIRECT_URI": "https://staging.tasktrain.app/assets/scripts/oauth-callback.html",
  "GRAPHQL_PATH": "/graphql",
  "HELPCRUNCH_APP_ID": "cb6236c2-5940-40e2-9262-770003699dcb",
  "HELPCRUNCH_SECRET": "87zEUc9ZyEBhGYOk5ZzFsltV414Qocss7ZbzG/MA4BKXnHt+MzqJawr6xPVFC3driAG5wAdmU0l5926I0rjHeQ==",
  "NAME": "STAG",
  "SERVER_HOST": "staging-server.tasktrain.app",
  "SERVER_PORT": "443",
  "SERVER_SCHEME_SECURE": true,
  "STRIPE_KEY": "pk_test_Ts4QUeE4Gp6ykLU186N9JeqN00OaAhnwXC",
  "VERSION": "1.23.1"
};
