import { Injectable } from '@angular/core';
import { DeleteObjectsOutput, GetObjectCommand, GetObjectOutput, PutObjectOutput, S3, S3ClientConfig } from '@aws-sdk/client-s3';
import { S3RequestPresigner } from '@aws-sdk/s3-request-presigner';
import { createRequest } from '@aws-sdk/util-create-request';
import { formatUrl } from '@aws-sdk/util-format-url';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '@environment/browser';
import { AccessTokenServiceProvider } from '@tasktrain/shared';
import { AccessTokenReadService } from '../gql-operations/utility/access-token-read.service';
import { ViewStateParametersReadService } from '../gql-operations/_client/view-state-parameters-read.service';
import { ViewStateParameters } from './view-state/view-state-parameters.model';


@Injectable({
	providedIn: 'root',
})
export class FileStorageService {
	private s3Configuration: S3ClientConfig;
	private s3Client: S3;
	private s3RequestPresigner: S3RequestPresigner;

	public constructor(
		private accessTokenReadService: AccessTokenReadService,
		private viewStateParametersReadService: ViewStateParametersReadService,
	) {
	}

	/** Fetches AWS S3 key and keeps up-to-date on `accountId` changes (even though the key is currently static) */
	private async ensureInitialized(): Promise<void> {
		if (!this.s3Client || !this.s3RequestPresigner) {
			await firstValueFrom(
				this.viewStateParametersReadService.mappedWatch().pipe(
					map((viewStateParameters: ViewStateParameters) => {
						return viewStateParameters.accountId || viewStateParameters.exchangePath; // @ToDo: send userId for unique encryption if no accountId available because on Exchange.
					}),
					filter((accountId) => {
						return !!accountId;
					}),
					distinctUntilChanged(),
					switchMap((accountId) => {
						return this.accessTokenReadService.fetchDecryptedToken({
							accountId: accountId,
							serviceProvider: AccessTokenServiceProvider.AWSS3,
						});
					}),
					tap((accessTokenResponse) => {
						this.s3Configuration = {
							credentials: {
								accessKeyId: accessTokenResponse.key,
								secretAccessKey: accessTokenResponse.secret,
							},
							region: environment.AWS_CONTENT_BUCKET_REGION,
							requestChecksumCalculation: 'WHEN_REQUIRED', // @ToDo: Remove workaround once https://github.com/aws/aws-sdk-js-v3/issues/6834 fixed
						};
						this.s3Client = new S3(this.s3Configuration);
						this.s3RequestPresigner = new S3RequestPresigner({
							credentials: this.s3Configuration.credentials,
							region: this.s3Configuration.region,
							sha256: Sha256,
						});
					}),
				),
			);
		} else {
			return Promise.resolve();
		}
	}

	public async deleteFiles(payload: string): Promise<DeleteObjectsOutput> {
		await this.ensureInitialized();
		return this.s3Client.deleteObjects({
			Bucket: environment.AWS_CONTENT_BUCKET_NAME,
			Delete: {
				Objects: [{ Key: FileStorageService.getS3ObjectKey(payload) }],
			},
		});
	}

	public async uploadFile(file: File, fileName?: string): Promise<PutObjectOutput> {
		await this.ensureInitialized();
		return this.s3Client.putObject({
			Bucket: environment.AWS_CONTENT_BUCKET_NAME,
			Key: fileName,
			Body: file,
			ContentDisposition: 'attachment',
			ContentType: file.type,
			ServerSideEncryption: 'AES256',
		});
	}

	public async getSignedUrl(payload: string, expirationSeconds: number = 15 * 60): Promise<string> {
		await this.ensureInitialized();
		const httpRequest = await createRequest(
			this.s3Client,
			new GetObjectCommand({
				Bucket: environment.AWS_CONTENT_BUCKET_NAME,
				Key: FileStorageService.getS3ObjectKey(payload),
			}),
		);
		const presignedRequest = await this.s3RequestPresigner.presign(httpRequest, { expiresIn: expirationSeconds });
		return formatUrl(presignedRequest);
	}

	public async getS3Object(objectKey: string): Promise<GetObjectOutput> {
		await this.ensureInitialized();
		return this.s3Client.getObject({
			Bucket: environment.AWS_CONTENT_BUCKET_NAME,
			Key: objectKey,
		});
	}

	private static getS3ObjectKey(payload: string): string {
		return payload?.slice(payload.lastIndexOf('/') + 1);
	}
}
