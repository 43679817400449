/** [common HTTP Status Codes](https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html) with TaskTrain-specific usage */
export enum ResponseStatus {
	/** Successful request (other than `Create` Mutation) */
	Success = 200,
	/** Successful `Create` Mutation */
	Created = 201,
	/** Request validation failed */
	BadRequest = 400,
	/** Not authenticated */
	Unauthorized = 401,
	/** Not authorized */
	Forbidden = 403,
	/** Database miss */
	NotFound = 404,
	/** Mailgun webhook reject with no retry <https://documentation.mailgun.com/docs/mailgun/user-manual/receive-forward-store/#route-actions> */
	NotApplicable = 406,
	Timeout = 408,
	Gone = 410,
	/** Unexpected/unknown error */
	InternalServerError = 500,
	NotImplemented = 501,
	BadGateway = 502,
	ServiceUnavailable = 503,
	GatewayTimeout = 504,
}
