import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


export enum AppRoutingPaths {
	Auth = 'auth',
	Main = 'main',
	RelativePath = './',
	Parent = '../',
}

export enum RoutingQueryParameters {
	CompletionStatus = 'CS',
	AssignmentArchived = 'AA',
	CommentArchived = 'CA',
	FeedbackArchived = 'FA',
	ProcedurePublished = 'PP',
	ProcedureDraft = 'PD',
	ProcedureArchived = 'PA',
	Layout = 'L',
	OrganizationTab = 'OT',
	ManualTab = 'MT',
	ProcedureTab = 'PT',
	ProcedureStepTab = 'ST',
	AssignmentTab = 'AT',
	AssignmentTaskTab = 'TT',
	ExchangeTab = 'ET',
}

export enum RoutingQueryArgumentLayout {
	List = 'list',
	Detail = 'detail',
	Full = 'full'
}

export enum AuthRoutingPaths {
	SignIn = 'sign-in',
	Register = 'register',
	ForgotPassword = 'forgot-password',
	SetPassword = 'set-password',
	EnrollAccount = 'enroll-account',
	VerifyEmail = 'verify-email',
	VerifyUsername = 'verify-username',
	AuthorizeOAuthClient = 'authorize-client'
}

export enum MainRoutingPaths {
	Dashboard = 'dashboard',
	Inbox = 'inbox',
	Library = 'library',
	Exchange = 'exchange',
	RichTextViewDialog = 'rich-text-view',
	OrganizationCreateDialog = 'organization-create',
	OrganizationInviteDialog = 'organization-invite',
	UserProfileDialog = 'user-profile',
	ProcedureSelectDialog = 'procedure-select',
	ProcedureAssignDialog = 'procedure-assign',
	ZapierPromotionDialog = 'zapier-promotion',
	AddOrRemoveFollowerDialog = 'add-or-remove-follower',
	CommentAttachedEditDialog = 'comment-attached-edit',
}

export enum OrganizationTab {
	Info = '0',
	MyAccount = '1',
	Access = '2',
	Billing = '3'
}

export enum LibraryRoutingPaths {
	Steps = 'steps',
	ContentEmbeddedCreateDialog = 'content-embedded-create',
	ContentEmbeddedEditDialog = 'content-embedded-edit',
	ManualAddMembersDialog = 'manual-add-members',
	ProcedureCreateDialog = 'procedure-create',
	ProcedureMoveOrCopyDialog = 'procedure-move-or-copy',
	ProcedurePrintOrExportDialog = 'procedure-print-or-export',
	Procedures = 'procedures',
	ManualAssignments = 'manual',
	ProcedureAssignments = 'procedure',
	Tasks = 'tasks'
}

export enum ManualTabs {
	Info = '0',
	Action = '1',
	Access = '2',
	Content = '3',
	AssignmentSummary = '4'
}

export enum ProcedureTab {
	Content = '0',
	Info = '1',
	Action = '2',
	AssignmentSummary = '3',
	Activity = '4'
}

export enum ProcedureStepTab {
	Content = '0',
	Info = '1',
	Activity = '2'
}

export enum InboxRoutingPaths {
	Assignments = 'assignments',
	Comments = 'comments',
	Feedback = 'feedback',
	Tasks = 'tasks',
	Account = 'account',
	Organization = 'organization'
}

export enum AssignmentTab {
	Help = '0',
	Info = '1',
	Actions = '2',
	Comments = '3',
	Activity = '4'
}

export enum AssignmentTaskTab {
	Info = '0',
	Help = '1',
	Comments = '2',
	Activity = '3'
}

export enum ExchangeRoutingPath {
	Public = 'public',
	Examples = 'examples',
	Steps = 'steps',
	ProcedureCopyDialog = 'procedure-copy',
}

// https://github.com/angular/angular-cli/issues/10582#issuecomment-523763194
/* eslint-disable arrow-parens */
const routes: Routes = [
	{ path: '', redirectTo: AppRoutingPaths.Auth, pathMatch: 'full' },
	{ path: AppRoutingPaths.Main, loadChildren: () => import('./main/main.module').then((m) => m.MainModule) },
	{ path: '**', redirectTo: AppRoutingPaths.Auth },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { defaultQueryParamsHandling: 'merge' })],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
